













import { Component, Vue } from "vue-property-decorator";
import ErasmusMap from "@/components/ErasmusMap.vue";

@Component({
  components: {
    ErasmusMap
  }
})
export default class App extends Vue {}
