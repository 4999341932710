








import { Component, Prop, Vue } from "vue-property-decorator";
import { LatLng } from "leaflet";

@Component
export default class University extends Vue {
  @Prop() readonly point!: LatLng;
  @Prop() readonly university!: object;
}
