







































































































import { Component, Vue } from "vue-property-decorator";
import { LatLng, latLng } from "leaflet";
import AgreementMarker from "@/components/AgreementMarker.vue";
import University from "@/components/University.vue";
import { IUniversity } from "@/interfaces/IUniversity";
import { IPerson } from "@/interfaces/IPerson";
import { IAgreement } from "@/interfaces/IAgreement";
import AgreementItem from "@/components/AgreementItem.vue";
import { IIsced } from "@/interfaces/IIsced";
import iscedJson from "@/assets/isced-codes.json";
import erasmusGeoJson from "@/assets/erasmus-locations.geo.json";

@Component({
  components: { AgreementItem, AgreementMarker, University }
})
export default class ErasmusMap extends Vue {
  zoom = 4;
  center = latLng(54, 10);
  mapOptions = {
    zoomSnap: 0.5
  };
  currentCenter = latLng(50, 10);
  currentZoom = 5;
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  attribution = `&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`;
  iscedFull: IIsced[] = [];
  erasmusLocations: IAgreement[] = [];

  async created() {
    this.iscedFull = iscedJson.map(i => {
      const isced: IIsced = {
        order: i["Order"],
        level: i["Level"],
        sortingCode: i["Sorting code"],
        parent: String(i["Parent"]),
        code: i["Code"].replaceAll("F", ""),
        description: i["Description"],
        remark: i["Remark"]
      };

      return isced;
    });

    this.erasmusLocations = this.parseAgreements(erasmusGeoJson);

    this.iscedOptions = [
      ...new Set(this.erasmusLocations.map(e => e.isced))
    ].sort();
    this.lengthOptions = [
      ...new Set(this.erasmusLocations.map(e => e.length))
    ].sort();
    this.gerEnOptions = [
      ...new Set(this.erasmusLocations.map(e => e.gerEn))
    ].sort();
    this.gerCountryOptions = [
      ...new Set(this.erasmusLocations.map(e => e.gerCountry))
    ].sort();
    this.cityOptions = [
      ...new Set(
        this.erasmusLocations.map(
          e => `${e.university.city}, ${e.university.country}`
        )
      )
    ].sort();
  }

  selectedIsced: IIsced[] = [];
  iscedOptions: IIsced[] = [];

  selectedLength: string[] = [];
  lengthOptions: string[] = [];

  selectedGerEn: string[] = [];
  gerEnOptions: string[] = [];

  selectedGerCountry: string[] = [];
  gerCountryOptions: string[] = [];

  selectedCities: string[] = [];
  cityOptions: string[] = [];

  showBachelor = true;
  showMaster = true;
  showDoubleDegree = true;

  circleMarkerHover: LatLng | null = null;

  hoverOverAgreement(agreement, enter) {
    if (enter) {
      this.circleMarkerHover = agreement.university.location;
    } else {
      this.circleMarkerHover = null;
    }
  }

  get selectedAgreements() {
    return this.erasmusLocations
      .filter(el =>
        this.selectedIsced.length > 0
          ? this.selectedIsced.includes(el.isced)
          : true
      )
      .filter(el =>
        this.selectedLength.length > 0
          ? this.selectedLength.includes(el.length)
          : true
      )
      .filter(el =>
        this.selectedGerEn.length > 0
          ? this.selectedGerEn.includes(el.gerEn)
          : true
      )
      .filter(el =>
        this.selectedCities.length > 0
          ? this.selectedCities.includes(
              `${el.university.city}, ${el.university.country}`
            )
          : true
      )
      .filter(el =>
        this.selectedGerCountry.length > 0
          ? this.selectedGerCountry.includes(el.gerCountry)
          : true
      )
      .filter(
        el =>
          (el.bsc && this.showBachelor) ||
          (el.msc && this.showMaster) ||
          (el.doubleDegree && this.showDoubleDegree)
      )
      .sort((a, b) =>
        a.university.university > b.university.university ? 1 : -1
      );
  }

  zoomUpdate(zoom: number) {
    this.currentZoom = zoom;
  }

  centerUpdate(center: LatLng) {
    this.currentCenter = center;
  }

  parseAgreements(erasmusLocation) {
    const erFeat = erasmusLocation.features;

    const uniNames = [...new Set(erFeat.map(e => e.properties.university))];
    const universities: IUniversity[] = uniNames.map(u => {
      const loc = erFeat.find(e => e.properties.university === u).geometry
        .coordinates;
      return {
        university: String(u),
        city: String(
          erFeat.find(e => e.properties.university === u).properties.city
        ),
        country: String(
          erFeat.find(e => e.properties.university === u).properties.country
        ),
        location: latLng(loc[1], loc[0])
      };
    });

    // universities.forEach(uni => {
    //   const sameLoc = universities.filter(u => u.location.equals(uni.location));
    //   if (sameLoc.length > 1) {
    //     sameLoc.forEach((sl, idx) => {
    //       //eslint-disable-next-line
    //       sl["location"] = this.geomUtils.destination(
    //         sl.location,
    //         (360 / sameLoc.length) * idx + 90,
    //         2500
    //       );
    //     });
    //   }
    // });

    const agreements: IAgreement[] = erFeat.map((agreement, idx) => {
      const agr = agreement.properties;
      const uni = universities.find(u => u.university === agr.university);
      const responsible: IPerson | null = agr.responsible
        ? {
            firstname: String(agr.firstname_resp),
            lastname: String(agr.lastname_resp),
            title: String(agr.title_resp || ""),
            phone: String(agr.phone_resp),
            email: String(agr.email_resp),
            secr: String(agr.secr_resp)
          }
        : null;

      const contact: IPerson | null = agr.contact
        ? {
            firstname: String(agr.firstname_contact),
            lastname: String(agr.lastname_contact),
            title: String(agr.title_contact || ""),
            phone: String(agr.phone_contact),
            email: String(agr.email_contact),
            secr: String(agr.secr_contact)
          }
        : null;

      const isced = this.iscedFull.find(i => i.code === String(agr.isced));

      return {
        key: idx,
        isced: isced,
        gerEn: String(agr["ger-en"] || ""),
        gerCountry: String(agr["ger-country"] || ""),
        msc: Boolean(agr.msc),
        bsc: Boolean(agr.bsc),
        doubleDegree: Boolean(agr.doubledegree),
        length: String(agr.length),
        extra: String(agr.extra || ""),
        group: Number(agr.group),
        responsible: responsible,
        contact: contact,
        university: uni
      };
    });

    return agreements;
  }
}
