import Vue from "vue";
import App from "./App.vue";

import {
  LMap,
  LTileLayer,
  LMarker,
  LGeoJson,
  LPopup,
  LCircleMarker
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import Multiselect from "vue-multiselect";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-geo-json", LGeoJson);
Vue.component("l-circle-marker", LCircleMarker);
Vue.component("l-markercluster", Vue2LeafletMarkerCluster);
Vue.component("multiselect", Multiselect);

type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount("#app");
