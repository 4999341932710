import { render, staticRenderFns } from "./AgreementItem.vue?vue&type=template&id=10938ec4&scoped=true&"
import script from "./AgreementItem.vue?vue&type=script&lang=ts&"
export * from "./AgreementItem.vue?vue&type=script&lang=ts&"
import style0 from "./AgreementItem.vue?vue&type=style&index=0&id=10938ec4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10938ec4",
  null
  
)

export default component.exports