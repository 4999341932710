





















import { Component, Prop, Vue } from "vue-property-decorator";
import { IPerson } from "../interfaces/IPerson";

@Component
export default class Person extends Vue {
  @Prop() readonly person!: IPerson;
}
