



















































import { Component, Prop, Vue } from "vue-property-decorator";
import { LatLng } from "leaflet";
import { IAgreement } from "@/interfaces/IAgreement";
import Person from "@/components/Person.vue";
@Component({
  components: { Person }
})
export default class AgreementMarker extends Vue {
  @Prop() readonly point!: LatLng;
  @Prop() readonly agreement!: IAgreement;
}
